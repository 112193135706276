/**
 * This reusable function will return a set of array of number
 * @param {bigint} maxNumber
 * @returns {array}
 */
export const generateNumberArray = (maxNumber: number): number[] => {
  if (typeof maxNumber !== 'number') return [];
  let returnArray = [];
  for (let i = 1; i <= maxNumber; i++) {
    returnArray.push(i);
  }
  return returnArray;
};
