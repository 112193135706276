import React, { useState } from 'react';
import { generateNumberArray } from './helpers/GlobalHelpers';
import { apiCall } from './helpers/ApiCallHelper';
import { TApiResults } from './types/returningResult';

function App() {
  //Redux looks is overkill in this small test. only use state
  const [selectedBallNumbers, setSelectedBallNumbers] = useState<number[]>([]);
  const [selectedPowerball, setSelectedPowerball] = useState<number | null>();
  const [loadingEnable, setLoadingEnable] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const allBallsForFirstSection = generateNumberArray(35);
  const allBallsForSecondSection = generateNumberArray(20);

  /**
   * Handling the event when user interact with checkbox
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const updateSelectedBallNumbers = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    const isChecked = checked;
    const selectedValue = Number(value);
    const totalSelectedBalls = selectedBallNumbers?.length || 0;

    if (isChecked && totalSelectedBalls < 7) {
      //We only add a new ball when total selected number still under 7
      setSelectedBallNumbers([...selectedBallNumbers, selectedValue]);
    } else if (!isChecked) {
      //Removed selected ball to enable toggle functions.
      const updateSelectedBalls = selectedBallNumbers.filter(
        (selectBallNumber) => selectBallNumber !== selectedValue
      );
      setSelectedBallNumbers(updateSelectedBalls);
    }
  };

  /**
   * Function to render the available number of ball can allow to select in the UI
   * @param {number[]}selectedBallNumbers
   * @returns {HTMLBodyElement} render the items
   */
  const renderEmptyDom = (selectedBallNumbers: number[]) => {
    const totalSelected = selectedBallNumbers?.length || 0;
    const emptyDomNumber = 7 - totalSelected;
    const emptyDoms = generateNumberArray(emptyDomNumber);
    return emptyDoms.map((emptyDom, index) => (
      <span
        key={`emptyDom_${emptyDom}`}
        className={`number_not_select_yet ${
          index === 0 ? 'first_element' : ''
        }`}
      ></span>
    ));
  };

  /**
   * function to clear all selected balls
   */
  const clearSelectedValues = () => {
    setSelectedBallNumbers([]);
    setSelectedPowerball(null);
  };

  /**
   * Function to call API and auto fill Previous week numbers
   * @returns
   */
  const autoFillPreviousNumbers = () => {
    setLoadingEnable(true);
    const requestType = 'POST';
    const ApiUrl =
      'https://data.api.thelott.com/sales/vmax/web/data/lotto/latestresults';
    const requestBody = {
      CompanyId: 'GoldenCasket',
      MaxDrawCountPerProduct: 1,
      OptionalProductFilter: ['Powerball'],
    };

    return apiCall(requestType, ApiUrl, requestBody)
      .then((returnData: TApiResults) => {
        if (returnData.Success !== true) {
          const returnErrorMessage =
            returnData?.ErrorInfo?.DisplayMessage ||
            'Error on return previoue week number, Please try again.';
          setErrorMessage(returnErrorMessage);
          return;
        }
        setSelectedBallNumbers(
          returnData?.DrawResults?.[0]?.PrimaryNumbers || []
        );
        setSelectedPowerball(
          returnData?.DrawResults?.[0]?.SecondaryNumbers?.[0] || null
        );
        setLoadingEnable(false);
      })
      .catch((e: any) => {
        console.error('Error on return previoue week number:', e);
        setErrorMessage(`Error on return previoue week number: ${e}`);
        setLoadingEnable(false);
      });
  };

  return (
    <div className='App'>
      {loadingEnable && <div className='loading'>Loading...</div>}
      <header>
        <ul>
          <li>1</li>
          <li className='selected_number'>
            {selectedBallNumbers.map((selectedBall, index) => {
              return (
                <span key={`selectedBallNumberDom_${index}`}>
                  {selectedBall}
                </span>
              );
            })}
            {renderEmptyDom(selectedBallNumbers)}
            <span
              className={`power_ball_dom ${
                selectedPowerball ? 'selected' : ''
              }`}
            >
              {selectedPowerball || 'PB'}
            </span>
          </li>
          <li>
            <button className='auto_fill' onClick={autoFillPreviousNumbers}>
              Auto Fill
            </button>
            <button className='clear' onClick={clearSelectedValues}>
              Clear
            </button>
          </li>
        </ul>
      </header>
      <main>
        <form>
          <section className='first_section'>
            {allBallsForFirstSection.map((ballNumber) => {
              return (
                <label
                  key={`checkbox_${ballNumber}`}
                  className={
                    selectedBallNumbers.includes(ballNumber) ? 'checked' : ''
                  }
                >
                  <span>{ballNumber}</span>
                  <input
                    type='checkbox'
                    name='selectedNumbers'
                    value={ballNumber}
                    checked={selectedBallNumbers.includes(ballNumber)}
                    onChange={updateSelectedBallNumbers}
                  />
                </label>
              );
            })}
          </section>
          <p>SELECT YOUR POWERBALL</p>
          <section className='second_section'>
            {allBallsForSecondSection.map((powerballNumber) => {
              return (
                <label
                  key={`radio_${powerballNumber}`}
                  className={
                    selectedPowerball === powerballNumber ? 'checked' : ''
                  }
                >
                  <span>{powerballNumber}</span>
                  <input
                    type='radio'
                    name='selectedPowerball'
                    value={powerballNumber}
                    checked={selectedPowerball === powerballNumber}
                    onChange={() => setSelectedPowerball(powerballNumber)}
                  />
                </label>
              );
            })}
          </section>
        </form>
        {errorMessage && <p className='error_message'>{errorMessage}</p>}
      </main>
    </div>
  );
}

export default App;
