/**
 * Function to call API endpoint
 * @param {string} type
 * @param {string} url
 * @param {unknown} requestBody
 * @returns {object}
 */
const apiCall = (type: string, url: string, requestBody: unknown) => {
  const withoutBodyType = ['GET'];
  const requestType = type.toUpperCase();
  const fetchHeader = {
    method: requestType,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  //Append body if the request type is not GET
  const requestHeader = withoutBodyType.includes(requestType)
    ? fetchHeader
    : {
        ...fetchHeader,
        body: JSON.stringify(requestBody),
      };
  //Call API and return data
  return fetch(url, requestHeader)
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      //Console log the error and return empty object.
      console.error('error:' + err);
      return {};
    });
};

export { apiCall };
